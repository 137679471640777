<template>
  <v-app>
    <v-main>
      <v-container class="px-12 py-12 fill-height" fluid>
        <v-row>
          <v-col lg="10" cols="md" class="pb-0">
            <h1 class="font-weight-light">Companies</h1>
          </v-col>
          <v-col lg="2" cols="md" class="pb-0">
            <v-btn
              @click="dialogCreateNewCompany = true"
              class="primary font-weight-light"
              >New Company</v-btn
            >
          </v-col>
        </v-row>

        <v-row v-if="getMyCompanies.length > 0">
          <v-col
            v-for="company in getMyCompanies"
            :key="company.id"
            lg="4"
            cols="md"
            class="pb-0"
          >
            <v-card
              class="mx-auto text-center"
              @click="setActiveCompany(company)"
            >
              <v-row class="pa-3">
                <v-col offset="1" cols="10" class="center relative">
                  <v-avatar size="160">
                    <v-img
                      class="card-img"
                      :src="
                        company.companyAvator !== undefined
                          ? company.companyAvator
                          : ''
                      "
                    ></v-img>
                  </v-avatar>
                </v-col>

                <v-col offset="1" cols="10" class="background">
                  <v-row>
                    <v-col>
                      <div
                        class="text-center mb-3 title text-secundario font-weight-bold"
                      >
                        {{
                          company.companyName !== undefined
                            ? company.companyName
                            : ""
                        }}
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row v-else>
          <v-col lg="4" cols="sm" class="pb-2">
            <CompanyListLoader />
          </v-col>
          <v-col lg="4" cols="sm" class="pb-2">
            <CompanyListLoader />
          </v-col>
          <v-col lg="4" cols="sm" class="pb-2">
            <CompanyListLoader />
          </v-col>
        </v-row>
      </v-container>
      <v-footer app class="py-3" elevation="2">
        <span class="ml-auto overline">Group NGS &copy;2021</span>
      </v-footer>

      <v-dialog v-model="dialogCreateNewCompany" width="600">
        <v-card>
          <v-card-title class="headline" primary-title>
            Create Company
          </v-card-title>
          <v-card-text class="pa-5">
            <v-form ref="sendForm" v-model="valid" lazy-validation>
              <v-card class="mb-4 pa-5">
                <v-text-field
                  v-model="getFreelancerCompany.companyName"
                  label="Company Name"
                  autocomplete="company"
                  outlined
                  dense
                  :rules="[rules.required]"
                ></v-text-field>

                <vuetify-google-autocomplete
                  id="map"
                  :country="['ug']"
                  v-bind:disabled="false"
                  classname="form-control"
                  prepend-inner-icon="place"
                  label="Company Location"
                  v-model="getFreelancerCompany.locationLabel"
                  outlined
                  dense
                  v-on:placechanged="getAddressData"
                  :rules="[rules.required]"
                >
                </vuetify-google-autocomplete>

                <v-text-field
                  v-model="getFreelancerCompany.companyEmail"
                  label="Company Email"
                  autocomplete="email"
                  outlined
                  dense
                  :rules="[rules.required]"
                ></v-text-field>

                <v-text-field
                  v-model="getFreelancerCompany.companyWebsite"
                  label="Company Website"
                  autocomplete="website"
                  outlined
                  dense
                  :rules="[rules.required]"
                ></v-text-field>

                <v-text-field
                  v-model="getFreelancerCompany.companyPhone"
                  label="Company Phone"
                  autocomplete="phone"
                  outlined
                  dense
                  :rules="[rules.required]"
                ></v-text-field>

                <v-textarea
                  v-model="getFreelancerCompany.companyDescription"
                  label="Company Description"
                  rows="4"
                  max-rows="4"
                  outlined
                  dense
                  :rules="[rules.required]"
                />

                <v-file-input
                  label="Company Avator"
                  v-model="file"
                  outlined
                  dense
                ></v-file-input>
              </v-card>
            </v-form>
          </v-card-text>
          <v-card-actions class="pa-5">
            <v-btn color="red" @click="dialogCreateNewCompany = false" outlined
              >Close</v-btn
            >
            <v-btn
              class="ml-auto"
              @click="validateNewCompanyForm"
              outlined
              color="primary"
              >Submit Company</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import Vue from "vue";
import firebase from "firebase";
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
import ActiveCompanyLoader from "@/components/loaders/ActiveCompanyLoader";
import CompanyListLoader from "@/components/loaders/CompanyListLoader";

export default {
  template: "#companies",

  components: {
    ActiveCompanyLoader,
    CompanyListLoader,
  },

  data: () => ({
    dialogCreateNewCompany: false,
    file: null,
    valid: false,
    getFreelancerCompany: {
      location: null,
    },
    rules: {
      required: (value) => !!value || "This field is required",
      email: (v) => /.+@.+\..+/.test(v) || "Must be a valid email",
    },
  }),

  beforeCreate() {
    this.$store.dispatch("fetchMyCompanies");
  },

  computed: {
    ...mapState(["myCompanies"]),
    ...mapGetters(["getMyCompanies", "messageAlert"]),
  },

  watch: {
    messageAlert(val) {
      if (val != null) {
        Vue.notify({
          group: "foo",
          title: val.title,
          text: val.body,
        });
        this.dialogCreateNewCompany = false;
        this.$store.dispatch("fetchMyCompanies");
      }
    },
  },

  methods: {
    validateNewCompanyForm() {
      if (this.$refs.sendForm.validate()) {
        // send it
        this.submitCompany();
      }
    },

    submitCompany() {
      var metadata = {
        contentType: "image/jpeg",
      };
      var goON = true;
      const context = this;

      if (context.file === null) {
        goON = false;
        this.$notify("error", "Registration Error", "Company Avator Required", {
          duration: 3000,
          permanent: false,
        });
      }

      if (goON) {
        // Create a root reference
        var storageRef = firebase.storage().ref();

        // Upload file and metadata to the object 'images/mountains.jpg'
        var uploadTask = storageRef
          .child("webimages/api/accounts/" + context.file.name)
          .put(context.file, metadata);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          function(snapshot) {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                console.log("Upload is paused");
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                console.log("Upload is running");
                break;
            }
          },
          function(error) {
            switch (error.code) {
              case "storage/unauthorized":
                // User doesn't have permission to access the object
                break;

              case "storage/canceled":
                // User canceled the upload
                break;

              case "storage/unknown":
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          },
          function() {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(function(downloadURL) {
                console.log("File available at", downloadURL);
                context.getFreelancerCompany.companyAvator = downloadURL;
                context.getFreelancerCompany.location = {};
                console.log(context.getFreelancerCompany);
                context.$store.dispatch(
                  "addNewCompany",
                  context.getFreelancerCompany
                );
                context.dialogCreateNewCompany = false;
                context.getFreelancerCompany = {};
              });
          }
        );
      }
    },

    getAddressData: function(addressData, placeResultData, id) {
      this.getFreelancerCompany.location = addressData;
    },

    setActiveCompany(company) {
      this.$store.dispatch("submitActiveCompany", company);
      this.$router.push({
        path: "/",
        query: {},
      });
    },
  },
};
</script>
